.App {
  text-align: center;
  font-family: "Raleway", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  font-size: 18px;
}

.spaceAround{
  width: calc(100vw - 4em);
  margin: auto;
}

.jumbotron {
  color: #404040;
  background: #a4c2f4;
  height: 11em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.myName {
  color: #eee8aa;
  font-size: 3em;
  margin: auto;
  white-space: nowrap;
}

.myDesc {
  line-height: 1.5em;
  font-size: 1.2em;
  margin: auto;
  padding: .5em;
}

.resume-link {
  font-weight: 500;
  color: #eee8aa;
}

#timeDate {
  position: absolute;
  top: 1em;
  right: 0em;
  padding: 1.2em;
  background-color: #faf6cf;
  color: #404040;
}

#bgImage {
  background-image: url("https://s3.us-east-2.amazonaws.com/rnbrakebill.com/flowers_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


@media (max-width: 887px) and (min-width: 538px) {
  .App {
    font-size: 12px;
  }
  .myDesc {
    font-size: 1.7em;
    word-break: inherit;
  }
  .myName {
    font-size: 4em;
  }
  .jumbotron {
    height: 15em;
  }
  #timeDate {
    font-size: 1.5em;
  }
}

@media (max-width: 886px) and (min-width: 681px) {
  .App {
    font-size: 10px;
  }
  #timeDate {
    font-size: 1.5em;
  }
}

@media (max-width: 680px) and (min-width: 546px) {
  .App {
    font-size: 8px;
  }
}

@media (max-width: 545px) and (min-width: 0px) {
  .App {
    font-size: 7.9px;
  }
  #timeDate {
    font-size: 1.5em;
  }
  .jumbotron {
    height: 25vh;;
  }
}

/* 
@media (min-width: 331px) and (max-width: 537px) {
  .App {
    font-size: 10px;
  }
  .myDesc {
    font-size: 1.3em;
    word-wrap: break-word;
  }
  .jumbotron {
    height: 15em;
  }
  #timeDate {
    font-size: 1.5em;
  }

}

@media (min-width: 247px) and (max-width: 330px) {
  .App {
    font-size: 7px;
  }
  .myName {
    font-size: 3.7em;
  }
  .myDesc {
    font-size: 1.9em;
    word-wrap: break-word;
  }
  .jumbotron {
    height: 25vh;
  }
  #timeDate {
    font-size: 1.5em;
  }

}

@media (min-width: 0px) and (max-width: 246px) {
  .App {
    font-size: 5px;
  }
  .myName {
    font-size: 7em;
  }
  .jumbotron {
    height: 40vh;
  }
  .myDesc {
    font-size: 3em;
    word-wrap: break-word;
  }
  #timeDate {
    font-size: 1.5em;
  }
} */
